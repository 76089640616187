import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Cardápio"
        keywords={[
          `batata recheada`,
          `lanches`,
          `pastéis`,
          `proções`,
          `pratos à la carte`,
          `pizzas doces`,
          `sucos`,
          `bebidas`,
          `disk entregas`,
        ]}
      />

      <Menu className="menu">
        <Slide left cascade duration={500}>
          <h1 className="menu-title">Pratos à La Carte</h1>
        </Slide>

        <div className="menu-sizes">
          <p>Individual</p>
          <p>Casal</p>
        </div>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Tilápia Parmegiana</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">95,00</h4>

              <h4 className="item-price">145,00</h4>
            </div>
          </div>

          <p className="item-desc">Acompanha: Arroz, Feijão, Fritas e Salada</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Filé de Tilápia</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">95,00</h4>

              <h4 className="item-price">145,00</h4>
            </div>
          </div>

          <p className="item-desc">Acompanha: Arroz, Feijão, Fritas e Salada</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Parmegiana de Filé Mignon</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">95,00</h4>

              <h4 className="item-price">145,00</h4>
            </div>
          </div>

          <p className="item-desc">Acompanha: Arroz, Feijão, Fritas e Salada</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Picanha</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">95,00</h4>

              <h4 className="item-price">145,00</h4>
            </div>
          </div>

          <p className="item-desc">Acompanha: Arroz, Feijão, Fritas e Salada</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Filé Mignon Acebolado</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">95,00</h4>

              <h4 className="item-price">145,00</h4>
            </div>
          </div>

          <p className="item-desc">Acompanha: Arroz, Feijão, Fritas e Salada</p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Salada Mista</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">30,00</h4>

              <h4 className="item-price">42,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Acompanha: Frango desfiado ou Atum + Alface, Tomate, Palmito, Ovos,
            Azeitonas, Milho e Ervilhas.
          </p>
        </Item>
      </Menu>
    </>
  );
};

export default IndexPage;
